import Chatbot from "./components/Chatbot.vue";
import { createVueApp } from "../vue";
import type {Component} from "vue";

declare global {
  interface Window {
    aiMessages: Backend.Chatbot.Messages;
  }
}

const app = createVueApp(Chatbot as Component, {messages: window.aiMessages})
app.mount("#avvoka-chatbot")
