<template>
  <div
    v-if="opened"
    class="w-96 h-96 bg-white rounded-lg flex flex-col justify-between"
  >
    <h2
      class="w-full avv-primary-bg-color avv-primary-bg-text rounded-t-lg text-center p-2 flex items-center gap-2"
    >
      <ASVG :name="'AvvokaLogo'" class="icon-icon" aria-hidden="true" />
      <span>Jeeves</span>
    </h2>
    <div
      ref="chatBox"
      class="w-full h-full my-1 flex flex-col overflow-y-auto p-2"
    >
      <div
        v-for="message in messages"
        class="message"
        :class="message.author"
        v-text="message.message"
      ></div>
    </div>
    <label class="relative flex items-center">
      <textarea
        v-model="userMessage"
        class="avv-input-tw border-0 border-t-2 rounded-none rounded-b-lg resize-none focus:outline-0 focus:border-0 focus:border-t-2 focus:border-gray-300 focus:ring-0"
        placeholder="Ask Jeeves anything..."
        @keydown.enter="submitMessage"
      />
      <div class="flex items-center justify-center h-full">
        <button
          class="border-t-2 border-gray-300 shadow-sm pr-2 grid place-items-center h-full"
          @click="submitMessage"
        >
          <i class="material-icons avv-gray-text">send</i>
        </button>
        <button
          class="border-t-2 border-gray-300 shadow-sm pr-2 grid place-items-center h-full"
          @click="deleteMessages"
        >
          <i class="material-icons avv-gray-text">delete</i>
        </button>
      </div>
    </label>
  </div>
  <button
    class="avv-primary-bg-color avv-primary-bg-text w-12 h-12 rounded-full grid place-items-center self-end justify-self-end cursor-pointer"
    @click="toggle"
  >
    <ASVG :name="opened ? 'Undo' : 'AvvokaLogo'"
      class="icon-icon"
      aria-hidden="true"
    />
  </button>
</template>

<script setup lang="ts">
import ASVG from '../../_abstract/ASVG.vue'
import AiApi from '@api/AiApi'
import { ref, reactive, watch, nextTick, watchEffect } from 'vue'
import { IsHTMLElement, IsHTMLInputElement } from '../../dom_utils'
import AutosaveUtils from '../../autosave/autosave_utils'
import LocationUtils from '../../location_utils'

const props = defineProps<{
  messages: Backend.Chatbot.Messages
}>()

const opened = ref(false)
const userMessage = ref('')

const toggle = () => {
  opened.value = !opened.value
}

const chatBox = ref<HTMLElement>()

watchEffect(() => {
  // scroll to the bottom of the chatbox on load
  if (chatBox.value) scrollDown()
})

const scrollDown = () => {
  nextTick(() => {
    if (!IsHTMLElement(chatBox.value)) return
    chatBox.value.scrollTop = chatBox.value.scrollHeight
  })
}

const messages = reactive<Backend.Chatbot.Messages>(props.messages)

watch(messages, scrollDown)

const processMessage = async (message: string) => {
  const responseMessage = await AiApi.chat<Backend.Chatbot.Message>({
    data: { message }
  })
  messages.push(responseMessage)
  if (responseMessage.redirect) {
    if (LocationUtils.isTemplateEdit()) AutosaveUtils.saveTemplate()
    else window.location.href = AiApi.action.path()
  }
}

const resetInput = () => {
  userMessage.value = ''
}

const submitMessage = () => {
  const data = { message: userMessage.value, author: 'user' }
  resetInput()
  messages.push(data)
  processMessage(data.message)
}

const deleteMessages = async () => {
  const response = await AiApi.clear()
  if (response.success) {
    messages.splice(0, messages.length)
    window.avv_dialog({
      snackMessage: 'Messages deleted successfully',
      snackStyle: 'success'
    })
  } else
    window.avv_dialog({
      snackMessage: 'Something went wrong while deleting messages',
      snackStyle: 'error'
    })
}
</script>

<style>
.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  width: fit-content;
}

.bot {
  align-items: flex-start;
}

.bot.message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.bot.message.last:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.bot.message.last:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.user {
  align-items: flex-end;
}

.user.message {
  color: white;
  margin-left: 25%;
  background-color: var(--avv-primary--color);
  background-attachment: fixed;
  position: relative;
  align-self: end;
}

.user.message.last:before {
  content: '';
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background-color: var(--avv-primary--color);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.user.message.last:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: 0;
  right: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}
</style>
